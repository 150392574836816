const _ = require('lodash')

const btc = require('./charts/btc-jpy.json')
const eth = require('./charts/eth-jpy.json')
const xrp = require('./charts/xrp-jpy.json')
const bch = require('./charts/bch-jpy.json')
const ltc = require('./charts/ltc-jpy.json')
const xym = require('./charts/xym-jpy.json')
const omg = require('./charts/omg-usd.json')
const enj = require('./charts/enj-usd.json')
const dot = require('./charts/dot-usd.json')
const ada = require('./charts/ada-usd.json')
const xem = require('./charts/xem-usd.json')
const link = require('./charts/link-usd.json')
const atom = require('./charts/atom-usd.json')


const createChartData = (_json) => {
    let chartData = []
    _.map(_json, (v, k) => {
        const date = new Date(parseInt(k))
        const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()]
        chartData.push({'date': `${year}-${month + 1}-${day}`, ...v})
    })

    return chartData
}


module.exports = {
    btc: createChartData(btc),
    eth: createChartData(eth),
    xrp: createChartData(xrp),
    bch: createChartData(bch),
    ltc: createChartData(ltc),
    xym: createChartData(xym),
    omg: createChartData(omg),
    enj: createChartData(enj),
    dot: createChartData(dot),
    ada: createChartData(ada),
    xem: createChartData(xem),
    link: createChartData(link),
    atom: createChartData(atom)
}