import React from 'react'
import styled from 'styled-components'
import {LineChart, Line, XAxis,  Tooltip} from 'recharts'



const Chart = ({coin, chart}) =>
    <Wrapper>
        <CoinHeader>
            <img src={`/images/coin_icons/${coin['icon']}`} alt={coin['name']}/>
            <span>{coin['symbol']}</span>
            <div>{coin['name']}</div>
        </CoinHeader>
        {/*<LineChart width={160} height={120} data={coin['chartData']}*/}
        {/*           margin={{top: 5, right: 30, left: 20, bottom: 5}}>*/}
        <LineChart width={160} height={120} data={chart}
                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey='date' hide={true}/>
            {/*<Tooltip/>*/}
            <Line type='monotone' dataKey='close' stroke={coin['color']} dot={false}/>
        </LineChart>
        <RateWrapper>
            <Rate></Rate>
            <Percent color='#03A66D'></Percent>
        </RateWrapper>
    </Wrapper>

export default Chart


const Wrapper = styled.div`
    padding: 12px 0;
    width: 160px;
    border: 1px solid rgba(0,0,0,0.07);
    border-radius: 10px;
    background-color: white;   
`

const CoinHeader = styled.div`
    display: grid;
    grid-template-columns: 25px auto;
    grid-template-rows: 25px auto;
    row-gap: 4px;
    margin: 0 12px 6px;
    
    img {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        align-self: center;
        width: 25px;
        height: 25px;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
    
    span {
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 700;
    }
    
    div {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        // justify-self: end;
        font-size: 12px;
        color: #888;
        text-transform: capitalize;
    }
`

const RateWrapper = styled.div`
    display: grid;
    text-align: right;
    padding: 0 12px;
`

const Rate = styled.div`
    font-size: 14px;
    font-weight: bold;
`

const Percent = styled.div`
    font-size: 12px;
    color: ${(props) => props.color};
`
